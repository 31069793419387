import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import CheckIn from './components/screens/CheckIn';
import './css/main.css'

function App() {
  const [orgData, setOrgData] = useState({});
  const [userData, setUserData] = useState({});
  const [userCheckInData, setUserCheckInData] = useState({})
  const cbnh = 'cpGridKonnect12';
  const mdkp = 'L1feStyle2WEalth#5161';

  const urlParams = new URLSearchParams(window.location.search);
  let params = urlParams.toString();
  const cpValue = params.substring(3, params.length);
  console.log(cpValue);

  let getOrgData = () => {
    let tok =  btoa(`${cbnh}:${mdkp}`)
    axios.get(`https://api.churchpad.com/v3/check-in-organization-info/?cp=${cpValue}`, {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers : { 'Authorization' : `Basic ${tok}` }
    }).then(res => {
        let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
        setOrgData(data);
    }).catch((err) => {
        console.log(err);
    })
  }

  let getUserData = () => {
    let tok =  btoa(`${cbnh}:${mdkp}`)
    axios.get(`https://api.churchpad.com/v3/check-in-user-info/?cp=${cpValue}`, {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers : { 'Authorization' : `Basic ${tok}` }
    }).then(res => {
        let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
        setUserData(data);
    }).catch((err) => {
        console.log(err);
    })
  }

  let getUserCheckInData = () => {
    let tok =  btoa(`${cbnh}:${mdkp}`)
    axios.get(`https://api.churchpad.com/v3/check-in-overview/?cp=${cpValue}`, {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers : { 'Authorization' : `Basic ${tok}` }
    }).then(res => {
        let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
        setUserCheckInData(data);
    }).catch((err) => {
        console.log(err);
    })
  }

  let getData = () => {
    getOrgData();
    getUserData();
    getUserCheckInData();
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <div className="App">
      <div className="main_container">
        <div className="main">
          <CheckIn
            orgData={orgData}
            userData={userData}
            userCheckInData={userCheckInData}
            getData={getData}
            cbnh={cbnh}
            mdkp={mdkp}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
