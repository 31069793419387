import React, {useEffect, useState} from 'react'
import Header from '../layout/Header'
import axios from 'axios';
import Event from '../layout/Event';
import MyGuests from '../layout/MyGuests';

export default function CheckIn (props) {
    const [values, setValues] = useState ({
        eventId: 0,
        usersToCheckIn: [],
        guestsToCheckIn: []
    })

    const [openedEvent, setOpenedEvent] = useState(false)
    let SetOpenedEvent = (index) => {
        setOpenedEvent(index);
    }

    const [sectionSelected, setSectionSelected] = useState('my-guests');
    let selectSection = (section) => {
        setSectionSelected(section);
    }

    let getData = (prop, data) => {
        setValues({...values, [prop]: data});
    }

    let checkIn = (eventId) => {
        let tok =  btoa(`${props.cbnh}:${props.mdkp}`);
        // if(hasErrors()) {return}

        let data = JSON.stringify({
            "userId": userData.userId,
            "orgId": orgData.orgId,
            "eventIdToCheckIn": eventId,
            "listOfUserIdsToCheckIn": [userData.userId, ...values.usersToCheckIn],
            "listOfGuestsInformationToCheckIn": values.guestsToCheckIn
        })

        axios.post('https://api.churchpad.com/v3/check-in-process/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            console.log('User has been checked in');
            if(res.data.responseMessage === 'success') {
                props.getData();
                setValues({...values, guestsToCheckIn: []});
            } else {
                // setSignInError(res.data.responseMessage)
            }
            console.log(res); 
        }).catch(err => {
            console.log(err);
        })
    }

    let checkOut = () => {
        let tok =  btoa(`${props.cbnh}:${props.mdkp}`);
        // if(hasErrors()) {return}

        let data = JSON.stringify({
            "userId": userData.userId,
            "orgId": orgData.orgId,
            "eventIdToCheckOut": '7',
            "listOfUserIdsToCheckOut": [userData.userId],
        })

        console.log(data);

        axios.post('https://api.churchpad.com/v3/check-out-process/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            console.log('User has been checked out');
            if(res.data.responseMessage === 'success') {
                props.getData();
                // window.location = res.data.responseData.successUrl;
            } else {
                // setSignInError(res.data.responseMessage)
            }
            console.log(res); 
        }).catch(err => {
            console.log(err);
        })
    }

    let orgData = props.orgData;
    let userData = props.userData;
    let userCheckInData = props.userCheckInData
    let checkInEventsList = userCheckInData.checkInEventsList
    let date = new Date();
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
    // console.log(userCheckInData);
    return (
        <>
            <div className="rsvpListContainer">
                <div className={`screen availableDatesScreen`}>
                    <Header 
                        firstName = {userData.userFirstName}
                        lastName = {userData.userLastName}
                        salutation={userData.userSalutation}
                        logo = {orgData.orgLogo}
                        profilePicture={userData.userProfilePicSmall}
                        orgShortName={orgData.orgShortName}
                        orgFullName={orgData.orgFullName}
                    />
                    <div className="title" onClick={checkOut}>
                        <h3><b>Today</b> {DAYS[date.getDay()]}, {MONTHS[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</h3>
                    </div>

                    <div className="section-toggle">
                        <button className="my-guests-section section waves-effect" id="my-guests" onClick={() => selectSection('my-guests')} style={sectionSelected === 'my-guests' ? {color: 'white'} : {}}>
                            <h4 id="my-guests">My Guests</h4>
                        </button>

                        <div className="section-selector" style={sectionSelected === 'my-guests' ? {left: '0%'} : {left: '50%'}}></div>

                        <button className="check-in-section section waves-effect" id="check-in" onClick={() => selectSection('check-in')} style={sectionSelected === 'check-in' ? {color: 'white'} : {}}>
                            <h4 id="check-in">Check In</h4>
                        </button>
                    </div>

                    {sectionSelected === 'check-in' ? <div className="events-container">
                        {
                            orgData.checkInEventsList ? orgData.checkInEventsList.map((item, index) => {
                                return (
                                    <Event
                                        index={index}
                                        item={item}
                                        key={index}
                                        openedEvent={openedEvent}
                                        setOpenedEvent={SetOpenedEvent}
                                        checkIn={checkIn}
                                        eventId={item.eventId}
                                        existingCheckIns={userCheckInData.myExistingCheckedInList}
                                    />
                                )
                            }) : null
                        }
                    </div> : 
                        <MyGuests
                            children={userData.userChildrenInfo}
                            spouse={userData.userSpouseInfo}
                            guests={userData.userGuestsInfo}
                            getData={getData}
                            usersToCheckIn={values.usersToCheckIn}
                            guestsToCheckIn={values.guestsToCheckIn}
                            selectSection={selectSection}
                            member={{
                                firstName: userData.userFirstName,
                                lastName: userData.userLastName
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}
