import React, {useState} from 'react'

export default function AddGuests(props) {
    const [values, setValues] = useState ({
        guestFirstName: '',
        guestLastName: '',
        guestPhoneNumber: ''
    })

    const [openAddGuest, setOpenAddGuest] = useState(false);
    let OpenAddGuest = () => {
        setOpenAddGuest(true);
    }
    let CloseAddGuest = () => {
        setOpenAddGuest(false);
        setInputError(false);
    }

    let handleInputChange = (prop) => (e) => {
        let value = e.target.value;
        if(prop === 'guestPhoneNumber') {
            value = value.split('-').join('');
            if(!isNumber(value) || value.length > 16) {return}
            // value = value.length < 11 ? props.formatPhoneNumber(value) : value;
        }
        setValues({...values, [prop]: value});
    }

    const isNumber = (value) => {
        let validNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        for(let i = 0; i < value.length; i++) {
          if(!validNumbers.includes(value[i])) {
            return false;
          }
        }
        return true;
      }

    const [inputError, setInputError] = useState(false);
    let addGuests = () => {
        for(const key in values) {
            if(key === 'guestPhoneNumber') {
                if(values[key].length < 3) {
                    setInputError('Please enter a valid phone number');
                    return;
                }
            } else if(values[key].length < 1) {
                setInputError(`Please enter a ${(key.split('_').join(' ')).replace('guest', '') }`);
                return;
            }
        }
        props.addGuests(values);
        setValues({
            guestFirstName: '',
            guestLastName: '',
            guestPhoneNumber: ''
        })
        CloseAddGuest();
    }
    
    return (
        <div className="add-guests">
            {!openAddGuest ? <button className="center waves-effect" onClick={OpenAddGuest}> {/* <span><i class="las la-plus"></i></span>  */}<h4>Add More Guests</h4></button> : null}
            <br/>
            {openAddGuest ? <div className="guest-form">
                <form className="col s12">
                    <div className="row">
                        <div className="input-field col s6" id="guestFirstName-container">
                            <input id="guestFirstName" type="text" className="validate" value={values.guestFirstName} onChange={handleInputChange('guestFirstName')}/>
                            <label htmlFor="guestFirstName" className={values.guestFirstName.length > 0 ? 'active' : ''}>First Name<sup>*</sup></label>
                        </div>
                        <div className="input-field col s6" id="phone-container">
                            <input id="guestLastName" type="text" className="validate" value={values.guestLastName} onChange={handleInputChange('guestLastName')}/>
                            <label htmlFor="guestLastName"  className={values.guestLastName.length > 0 ? 'active' : ''}>Last Name<sup>*</sup></label>
                        </div>
                        <div className="input-field col s6" id="guestPhoneNumber-container">
                            <input id="guestPhoneNumber" type="text" className="validate" value={props.formatPhoneNumber(values.guestPhoneNumber)} onChange={handleInputChange('guestPhoneNumber')}/>
                            <label htmlFor="guestPhoneNumber" className={values.guestPhoneNumber.length > 0 ? 'active' : ''}>Phone Number<sup>*</sup></label>
                        </div>
                    </div>
                    <div className="row">
                        {inputError ? <div className="error red-text"> <h4 className="center">{inputError}</h4> </div> : null}
                        <div className="button input-field waves-effect col s3" id="container" onClick={addGuests}>
                            {/* <span><i class="las la-plus"></i></span>  */}<h4>Add Guests</h4>
                        </div>

                        <div className="button input-field waves-effect col s3 red" id="container" onClick={CloseAddGuest}>
                            {/* <span><i class="las la-plus"></i></span>  */}<h4>Close</h4>
                        </div>
                    </div>
                </form>
                <br/>
            </div> : null}
        </div>
    )
}
