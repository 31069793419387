import React, {useState} from 'react'
import AddGuests from './AddGuests'

export default function MyGuests(props) {
    const [extraGuests, setExtraGuests] = useState([...props.guestsToCheckIn])
    let addGuests = (data) => {
        let guestList = extraGuests;
        guestList.push(data)
        setExtraGuests([...guestList]);
        props.getData('guestsToCheckIn', guestList)
    }
    let removeGuest = (e) => {
        let guestList = extraGuests;
        guestList.splice(e.target.id, 1);
        setExtraGuests([...guestList]);
        props.getData('guestsToCheckIn', guestList)
    }

    const [includedGuests, setIncludedGuests] = useState([...props.usersToCheckIn])
    let toggleAddIncludedGuest = (e) => {
        let id = e.target.id;
        let guests = includedGuests;
        if(includedGuests.includes(id)) {
            guests.splice(guests.indexOf(id), 1);
        } else {
            guests.push(id);
        }
        props.getData('usersToCheckIn', guests)
        setIncludedGuests([...guests])
    }

    let formatPhoneNumber = (phoneNumberString) => {
        phoneNumberString = phoneNumberString.split('-').join('');
        let newString = phoneNumberString.split('');
        if(newString.length > 3) {
            newString.splice(3, 0, '-');
            if(newString.length > 7) {
            newString.splice(7, 0, '-');
            }
        };
        newString = newString.join('');
        return newString
    }
    
    let children = props.children ? props.children : [];
    let spouse =  props.spouse ? props.spouse : false;
    let myGuests = props.guests ? props.guests : [];
    
    return (
        <div className="my-guests">
            <header>
                <h3>Select members and guests to check in</h3>
            </header>

            <AddGuests
                addGuests={(data) => addGuests(data)}
                formatPhoneNumber={formatPhoneNumber}
            />

            <div className="extra-guests-members members">
                {extraGuests.length > 0 ? <h3 className="header">Extra Guests</h3> : null}
                <ul>
                    {
                        extraGuests.map((item, index) => {
                        return (
                                <li key={index} id={index} onClick={removeGuest}> <h4 id={index}>{item.guestFirstName} {item.guestLastName} - {formatPhoneNumber(item.guestPhoneNumber)}</h4> <span id={index} className="includeMember"> <i className="las la-trash"></i> </span> </li>       
                            )
                        })
                    } 
                </ul>
            </div>

            <div className="member members">
                <h3 className="header">Member</h3>
                <ul>
                    <li>
                        <h4>{props.member.firstName} {props.member.lastName}</h4>
                        <span className="includeMember" style={{backgroundColor: 'gray', border: '1px solid gray'}}><i className="fas fa-check" id={spouse.spouseUserId}></i></span>
                    </li>
                </ul>
            </div>

            <div className="family-members members">
                {children.length > 0 || spouse ? <h3 className="header">Family</h3> : null}
                <ul>
                    {spouse ? <li onClick={toggleAddIncludedGuest} id={spouse.spouseUserId}>
                        <h4 id={spouse.spouseUserId}>{spouse.spouseFirstName} {spouse.spouseLastName}</h4>
                        <span className="includeMember" id={spouse.spouseUserId} style={includedGuests.includes(spouse.spouseUserId) ? {backgroundColor: 'green', border: '1px solid green'} : {}}><i className="fas fa-check" id={spouse.spouseUserId}></i></span>
                    </li> : null}
                    {
                        children.map((item, index) => {
                            return (
                                <li key={index} onClick={toggleAddIncludedGuest} id={item.childUserId}>
                                    <h4 id={item.childUserId}>{item.childFirstName} {item.childLastName}</h4>
                                    <span className="includeMember" id={item.childUserId} style={includedGuests.includes(item.childUserId) ? {backgroundColor: 'green', border: '1px solid green'} : {}}><i className="fas fa-check" id={item.childUserId}></i></span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className="guests-members members">
                {myGuests.length > 0 ? <h3 className="header">Guests</h3> : null}
                <ul>
                    {
                        myGuests.map((item, index) => {
                        return (
                                <li key={index} onClick={toggleAddIncludedGuest} id={item.guestUserId}>
                                    <h4 id={item.guestUserId}>{item.guestFirstName} {item.guestLastName}</h4>
                                    <span className="includeMember" id={item.guestUserId} style={includedGuests.includes(item.guestUserId) ? {backgroundColor: 'green', border: '1px solid green'} : {}}><i className="fas fa-check" id={item.guestUserId}></i></span>
                                </li>       
                            )
                        })
                    }
                </ul>
            </div>

            <div className="readyToCheckIn">
                <button className="goToCheckIn waves-effect waves-blue" onClick={() => props.selectSection('check-in')}>Ready To Check in</button>
            </div>
        </div>
    )
}
