import React, {useState, useEffect} from 'react'

export default function Event(props) {
    const [readyToCheckIn, setReadyToCheckIn] = React.useState(false);
    let convertMilitaryTimeToLocal = (time) => {
        let timeArr = time.split(':');
        let hour = timeArr[0];

        if(hour === 0) {
            timeArr[0] =  '12';
        } else if(hour > 12) {
            timeArr[0] = timeArr[0] - 12;
        };
        timeArr = timeArr.join(":");
        timeArr +=  hour > 12 ? ' PM' : ' AM'
        return timeArr[0] === '0' ? timeArr.replace('0', '') : timeArr
    }

    let toggleReadyToCheckIn = () => {
        setReadyToCheckIn(!readyToCheckIn);
        props.setOpenedEvent(props.index);
    }

    let closeReadyToCheckIn = () => {
        setReadyToCheckIn(false);
    }
    
    let checkIn = () => {
        props.checkIn(props.eventId);
        closeReadyToCheckIn()
    }

    React.useEffect(() => {
        if(props.openedEvent !== props.index) {
            setReadyToCheckIn(false);
        }
    }, [props.openedEvent])

    let item = props.item;
    let index =  props.index;

    const [userHasBeenCheckedIn, setUserHasBeenCheckedIn] = useState(false);
    useEffect(() => {
        let checkedIn = props.existingCheckIns ? props.existingCheckIns.some(prop => prop.existingCheckInEventId === props.eventId) : false;
        setUserHasBeenCheckedIn(checkedIn)
    }, [props.existingCheckIns])

    return (
        <div className="event-container" key={index} style={
            userHasBeenCheckedIn ? {backgroundColor: '#d9d9d9', opacity: '0.7'} : {}
        }>
            <div className="event" id={`event-${index}`} key={index} onClick={!userHasBeenCheckedIn ? toggleReadyToCheckIn : null} style={{cursor: userHasBeenCheckedIn ? "not-allowed" : 'pointer'}}>
                {
                    userHasBeenCheckedIn ? 
                    <div className="userIsCheckedIn red">
                        <h4>Checked In</h4>
                    </div> : null
                }
                <div className="time-container">
                    <div className="times">
                        <h4 className="start-time">{convertMilitaryTimeToLocal(item.startTime)}</h4>
                        <span>-</span>
                        <h4 className="end-time">{convertMilitaryTimeToLocal(item.endTime)}</h4>
                    </div>
                </div>

                <div className="event-desc-container">
                    <div className="event-desc">
                        <h3>{item.eventName}</h3>
                        <h4>{item.eventDescription}</h4>
                        <h4 className="location"> <span></span> {item.eventLocationName}</h4>
                    </div>
                </div>
            </div>

            <div className="checkIn-button-container" style={!userHasBeenCheckedIn  ? {position: 'relative', top: '0px', opacity: '1', zIndex: '1'} : {}}>
                <div className="options">
                    <button className="btn checkIn" id={props.eventId} onClick={checkIn}>
                        <h4 id={props.eventId}>Check in</h4> 
                    </button>
                    <button className="btn no" onClick={closeReadyToCheckIn}>
                        <h4>Cancel</h4> 
                    </button>
                </div>
            </div>
        </div>
    )
}
